import React from 'react';
import bigDecimal from 'js-big-decimal';
import {toast} from 'react-toastify';
import i18next from 'i18next';


export default class WorkListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            zeroWidth: 0,
            firstWidth: 0,
            secondWidth: 0,
            thirdWidth: 0,
            fourthWidth: 0,
            fifthWidth: 0,
            reloadKey: 0,
        };

        this.onChange = this.onChange.bind(this);
    }


    summary() {
        return this.state.data.workedHours.reduce((partial_sum, a) => {
            return bigDecimal.add(partial_sum, a);
        });
    }

    onChange(event) {
        const index = event.target.name.split("_")[1];
        let value = event.target.value.split(",").join(".");

        const numericValue = parseFloat(value);

        if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 24 || value.trim() === '') {
            let dt = Object.assign({}, this.state.data);
            dt.workedHours[index] = numericValue;
            this.setState({ data: dt });
            this.props.onChange(dt);
        } else {
            toast.error(i18next.t("daily-h-error"), {icon: false});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data.workedHours !== this.props.data.workedHours ||
            prevProps.data.usedHours !== this.props.data.usedHours) {
                this.setState({
                    data: this.props.data
                });
                this.summary();
        }
    }

    componentDidMount() {
        this.summary();
        const zeroWidth = this.zeroColumn.offsetWidth - 1;
        const firstWidth = this.firstColumn.offsetWidth - 1;
        const secondWidth = this.secondColumn.offsetWidth - 1;
        const thirdWidth = this.thirdColumn.offsetWidth - 1;
        const fourthWidth = this.fourthColumn.offsetWidth - 1;
        const fifthWidth = this.fifthColumn.offsetWidth - 1;
        this.setState({
            zeroWidth: zeroWidth,
            firstWidth: firstWidth,
            secondWidth: secondWidth,
            thirdWidth: thirdWidth,
            fourthWidth: fourthWidth,
            fifthWidth: fifthWidth
        })
    }


    result() {
        let result = [];
        let style = null;
        for (let i = 0; i < this.props.monthDefinition.days.length; i++) {
            style = !this.state.data.modifiable[i] ? { backgroundColor: '#d8e2ec', textAlign: 'center' } : { textAlign: 'center' };
            style = this.props.monthDefinition.days[i].free && this.state.data.modifiable[i] ? { backgroundColor: '#25a1ff', textAlign: 'center' } : style;
            result.push(<td>
                <input
                    type="number"
                    style={style}
                    name={this.props.data.taskId + "_" + i}
                    maxLength="5"
                    min="0"
                    max="24"
                    size="1"
                    value={this.state.data.workedHours[i]}
                    onChange={this.onChange}
                    readOnly={!this.state.data.modifiable[i]}
                    onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                            e.preventDefault();
                        }
                    }}
                />
            </td>);
        }
        return result;
    }

    styleElement(leftParam) {
        return {
            position: 'sticky',
            left: leftParam,
            backgroundColor: '#f0f2f5',
            marginLeft: 0,
            marginRight: 0
        }
    }

    render() {
        return (
            <tr>
                <td style={this.styleElement(0)}
                    ref={(zeroColumn) => this.zeroColumn = zeroColumn}>{this.props.data.projectCode}</td>
                <td style={this.styleElement(this.state.zeroWidth)}
                    ref={(firstColumn) => this.firstColumn = firstColumn}>{this.props.data.projectName}</td>
                <td style={this.styleElement(this.state.zeroWidth + this.state.firstWidth)}
                    ref={(secondColumn) => this.secondColumn = secondColumn}>{this.props.data.taskName}</td>
                <td style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth)}
                    ref={(thirdColumn) => this.thirdColumn = thirdColumn}>{this.props.data.taskCode}</td>
                <td style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth)}
                    ref={(fourthColumn) => this.fourthColumn = fourthColumn}>{this.props.data.plannedHours}</td>
                <td style={{...this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth), textAlign: 'center'}}
                    ref={(fifthColumn => this.fifthColumn = fifthColumn)}>{this.props.data.usedHours}</td>
                <td style={{...this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth + this.state.fifthWidth), textAlign: 'center'}}>{this.summary()}</td>
                {this.result()}
            </tr>
        );
    }
}